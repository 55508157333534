<template>
  <main :style="{'background-color': (quiz && quiz.settings.themeColor ? quiz.settings.themeColor:'')}"
        :class="quiz? quiz.settings.theme:''">
    <div class="container-fluid"
         :class="{challenge: quiz && quiz.type==='challenge', groups:quiz && quiz.groups.length>1}">
      <header :class="{slim: currSt==='LOGIN' || currSt==='FEEDBACK' }">
        <a href="#" @click="sidebar=!sidebar">
          <font-awesome-icon icon="bars"/>
        </a>
      </header>
      <sidebar :is-active="sidebar" :code="code" :show-whatsapp="quiz && quiz.settings.showWhatsapp"/>
      <modal-code v-if="showCodeModal" :is-quiz="!!quiz" :code="code" :lang="lang"
                  :title="quiz?quiz.name:''" @updateCode="updateCode"/>
      <modal-verify v-if="showVerifyModal" :is-quiz="!!quiz" :is-pin-locked="isPinLocked" :is-pin-wrong="isPinWrong"
                    :lang="lang" :played="played" :method="protectionType"
                    :title="quiz?quiz.name:''" @checkPin="checkPin" @clearWrongPin="()=> {isPinWrong=false}"
                    @newPin="newPin"/>
      <div v-if="currSt==='LOGIN'" class="screen screen-login text-center">
        <!--           :style="{'background-color':quiz &&quiz.settings.themeColor?quiz.settings.themeColor:''}"-->
        <div class="hero" :style="{'background-image':'url(\''+bgImage+'\')'}">
          <video class="hero-video" v-if="bgVideo" :src="bgVideo" :muted="true"
                 :loop="true" autoplay playsinline/>
          <transition name="slide">
            <label class="overlay">
              <span @click="showCodeModal=true">{{ showName }}</span>
            </label>
          </transition>
        </div>
        <div class="email-container animated flip"
             v-if="quiz && !quiz.settings.challengeAnonymous && !quiz.settings.allowIdentifier">
          <div class="half-top">
            <input type="email" v-model="player.email"
                   :class="{disabled: !quiz || !quiz.approved}"
                   :placeholder="(lang==='he'?'אימייל':'Email') + (quiz && quiz.settings.mobileProtect? ' *': (lang==='he'?' (לא חובה)':' (Optional)'))"/>
          </div>
        </div>
        <div v-if="quiz && quiz.settings.allowIdentifier" class="id-container">
          <div class="half-top">
            <input :type="protectionType" required :placeholder="quiz.settings.identifierPlaceholder"
                   :class="{disabled: !quiz || !quiz.approved}"
                   maxlength="32" v-model="player.identifier"/>
          </div>
        </div>
        <div v-if="quiz && quiz.settings.challengeAnonymous" class="placeholder-container"></div>
        <div class="name-container" v-show="quiz && !quiz.settings.challengeAnonymous">
          <div class="half-top">
            <input type="text" style="text-transform: capitalize;" :placeholder="lang==='he'?'שם מלא *':'Your name *'"
                   :class="{disabled: !quiz || !quiz.approved}"
                   maxlength="12" v-model="player.name"/>
            <h4 :style="{direction:lang==='he'?'rtl':'ltr'}">
              <font-awesome-icon icon="check" v-show="nameVerified"></font-awesome-icon>&nbsp;{{
                lang === 'he' ? '3-12 תווים' : '3-12 chars'
              }}
            </h4>
          </div>
        </div>
        <div v-if="quiz && quiz.groups.length>1" class="group-container">
          <div class="half-top">
            <select v-model="player.group" :style="{direction:lang==='he'?'rtl':'ltr'}">
              <option disabled selected>{{ lang === 'he' ? 'בחרו קבוצה' : 'Select a group' }}</option>
              <option :value="i" :key="'g-'+i" v-for="(g, i) in quiz.groups">{{ g }}</option>
            </select>
          </div>
        </div>
        <div class="login-container"
             :style="{'background-color':quiz &&quiz.settings.themeColor?quiz.settings.themeColor:'', color: quiz &&quiz.settings.themeColor2?quiz.settings.themeColor2:''}"
             :class="{disabled:!allowStart || (!quiz.approved && !isProtected ),'sm':quiz && quiz.settings.allowIdentifier, 'bg-green':!quiz || !quiz.settings.themeColor}"
             @click="start">
          <div class="half-top" :style="{direction:lang==='he'?'rtl':'ltr'}">
            <h3>
              {{ startText }}
              <font-awesome-icon icon="lock" v-show="!allowStart"></font-awesome-icon>
            </h3>
            <p v-show="!isConnected && !isProtected">
              {{
                lang === 'he' ? 'אתם לא מחוברים למשחק. בודק שוב...' : 'You are not connected. Trying again...'
              }}</p>
          </div>
        </div>
      </div>
      <div v-if="currSt==='INTRO'" class="screen screen-intro" :style="{direction:lang==='he'?'rtl':'ltr'}">
        <h2 :class="{'text-right':lang==='he'}">{{ lang === 'he' ? "איך משחקים?" : "How to play?" }}</h2>
        <h1 :class="{'text-right':lang==='he'}">{{ quiz.name }}</h1>
        <div class="text-container">
          <div class="text-area" :class="{'text-right':lang === 'he','text-left':lang !== 'he'}"
               v-html="quiz.settings.challengeMessageStart"></div>
          <p></p>
        </div>
        <div class="button-container text-center" :class="{'bg-green':!quiz || !quiz.settings.themeColor}"
             :style="{'background-color':quiz &&quiz.settings.themeColor?quiz.settings.themeColor:''}"
             @click="startChallenge">
          <span
              :style="{direction:lang==='he'?'rtl':'ltr', 'color':quiz &&quiz.settings.themeColor2?quiz.settings.themeColor2:''}">{{
              lang === 'he' ? 'התחל!' : 'Start!'
            }}
            <font-awesome-icon icon="play"/>
          </span>
        </div>
      </div>
      <div v-if="currSt==='CONTROLLER'|| currSt==='LEADERS'" :style="{direction:lang==='he'?'rtl':'ltr'}"
           class="header flex-apart flex-baseline">
        <div class="header-left">
          <div :class="{'text-right':lang==='he', 'text-left':lang!=='he'}" class="flex-start">
            <button :class="['btn-'+(player.group+1)]" class="btn btn-sm btn-primary"
                    v-if="!quiz.settings.challengeHideScore"
                    @click="!quiz.settings.hideLeadersMobile && quiz.type!=='challenge' ? currSt='LEADERS': false">
              <strong>{{ score }}</strong>
              <small>{{ lang === 'he' ? 'נקודות' : 'Score' }}</small>
            </button>
            <h1>{{ player.name }}</h1>
          </div>
          <p class="group-name" :class="{'text-right':lang==='he', 'text-left':lang!=='he'}"
             v-if="quiz && quiz.groups.length">{{ quiz.groups[player.group] }}</p>
        </div>
        <div class="header-right">
          <div class="camera" v-if="quiz && quiz.settings && quiz.settings.allowProfileImages">
            <div class="profile-image" id="pick-avatar"
                 :style='{"background-image":"url(\""+(player.image? player.image:defAssets.imgCam)+"\")"}'></div>
            <avatar-cropper
                @uploaded="handleUploaded"
                trigger="#pick-avatar"
                upload-url="https://www.quizygame.com/php/mobileImageUploadNew.php"
                :upload-form-data="{quizId:quiz.id, clientId:clientId}"
                :output-quality="0.6"
                :output-options="{width: 200, height: 200}"
                :labels="{ submit: 'אישור', cancel: 'ביטול'}"/>
          </div>
          <div v-else class="profile-image profile-avatar"
               :style='{"background-image":"url("+defAssets.imgAvatar+")"}'/>
          <div class="client text-center" v-if="clientId && quiz && quiz.type !== 'challenge'"
               :style="{direction:lang==='he'?'rtl':'ltr'}">
            <span>{{ lang === 'he' ? 'מזהה' : 'ID' }}</span>&nbsp;<span>{{ clientId }}</span>&nbsp;
          </div>
          <p class="question-number" v-if="quiz && quiz.type==='challenge'" style="direction:ltr">
            <span>{{ question.num + 1 }}</span>/<span>{{ quiz.questions.length }}</span></p>
        </div>
      </div>
      <div v-if="currSt==='CONTROLLER'" class="screen screen-controller"
           :style="{direction:lang==='he'?'rtl':'ltr'}">
        <div class="hero"
             :style="{'background-image':'url(\''+(!quiz.settings.showMainImageMobile && question.video ? '':(!quiz.settings.showMainImageMobile && question.image?question.image:bgImage))+'\')'}">
          <video @ended="videoEnded()" v-if="question.video || (quiz.settings.showMainImageMobile && bgVideo)"
                 :src="quiz.settings.showMainImageMobile? bgVideo:question.video" :muted="videoMute"
                 :loop="question.kind!=='content'" autoplay playsinline/>
          <button v-if="question.video" class="video-button" @click="videoMute=!videoMute">
            <img alt="ON" v-show="!videoMute" :src="defAssets.imgSoundOn">
            <img alt="OFF" v-show="videoMute" :src="defAssets.imgSoundOff">
          </button>
          <transition name="slide">
            <label v-if="question.ask" class="overlay text-center">
              <span>{{ question.ask }}</span>
            </label>
          </transition>
          <span v-if="question.kind !== 'content'" class="timer timer-gone" :style="{width:currTimeGone}"></span>
          <span v-if="question.kind !== 'content'" class="timer timer-left" :style="{width:currTimeLeft}"></span>
        </div>
        <div class="controller" v-if="question.kind!=='content'">
          <div v-if="question.type==='buzzer' && !quiz.settings.buzzAnswer" class="aController">
            <button @click="sendNumber('Rush')"
                    class="disableHover btn-1 animated bounceIn">Buzz!
            </button>
            <span v-show="!game.allowPlay" class="buzzer-disabled">
              <span>{{ lang === "he" ? "נא להמתין..." : "Ready?" }}</span>
              <span class="glass-effect"></span>
            </span>
          </div>
          <div v-else-if="question.type==='buzzer' && quiz.settings.buzzAnswer">
            <Pin :answer="question.ans[0].opt2" @answerSubmit="sendBuzzAnswer" :disabled="!game.allowPlay || game.wait"
                 :correct="game.correct"
                 :dir="lang==='he'?'rtl':'ltr'"/>
          </div>
          <div v-else-if="quiz.type === 'trivia' || quiz.settings.numericAnswer">
            <div class="flex-center">
              <button @click="sendNumber('A', 0)"
                      :class="{'grey-out': game.clickReceived >-1 && game.clickReceived !==0}"
                      class="button-3 btn-1 disableHover animated bounceIn">1
              </button>
              <button @click="sendNumber('B',1)"
                      :class="{'grey-out': game.clickReceived >-1 && game.clickReceived !==1}"
                      class="button-3 btn-2 disableHover">2
              </button>
              <button @click="sendNumber('C',2)"
                      :class="{'grey-out': game.clickReceived >-1 && game.clickReceived !==2}"
                      class="button-3 btn-3 disableHover animated bounceIn">3
              </button>
            </div>
            <div class="animated slideInUp flex-center" v-show="question.type!=='buzzer'">
              <button @click=" sendNumber(
        'D', 3)" :class="{'grey-out': game.clickReceived >-1 && game.clickReceived !==3}"
                      class="button-3 btn-4 disableHover">4
              </button>
              <button @click="sendNumber('E', 4)"
                      :class="{'grey-out': game.clickReceived >-1 && game.clickReceived !==4}"
                      class="button-3 btn-5 disableHover animated bounceIn">5
              </button>
              <button @click="sendNumber('F',5)"
                      :class="{'grey-out': game.clickReceived >-1 && game.clickReceived !==5}"
                      class="button-3 btn-6 disableHover">6
              </button>
              <span v-show="!game.allowPlay" class="buttons-disabled">
            <span>{{ lang === "he" ? "נא להמתין..." : "Ready?" }}</span>
            <span class="glass-effect"></span>
          </span>
            </div>
          </div>
          <div v-else class="buttons-full"
               :class="{ready: game.ready && !game.end, end: game.end, play: game.allowPlay}">
            <button v-for="(a,i) in question.ans" @click="sendNumber(['A','B','C','D','E','F'][i], i)" :key="'btn-'+i"
                    :class="{'answer-outline':challengeSelect===i,'answer-selected':game.correct===-1 && i=== game.clickReceived,'answer-correct':game.correct>-1 && game.correct===i,'answer-wrong':game.correct>-1 &&  i !== game.correct && i=== game.clickReceived,'disabled-transparent':!game.allowPlay && game.ready,'grey-out': (game.clickReceived >-1 || game.end && game.correct>-1)&& game.clickReceived !==i ,  'btn-1': i===0, 'btn-2': i===1, 'btn-3': i===2, 'btn-4': i===3, 'btn-5': i===4, 'btn-6': i===5}"
                    class="answer fade-in">{{ a.opt }}
            </button>
            <div v-show="!game.allowPlay && !game.end" class="buttons-overlay buttons-disabled">
              <span>{{ lang === "he" ? "נא להמתין..." : "Ready?" }}</span>
              <span class="glass-effect"></span>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="currSt==='LEADERS'" class="screen screen-leaders" :class="{'text-right':lang==='he'}"
           :style="{direction:lang==='he'?'rtl':'ltr'}">
        <button class="btn btn-danger mb-2" v-show="question.num"
                @click="allowStart?currSt='CONTROLLER':currSt='LOGIN'">
          {{ lang === 'he' ? `חזרה למשחק` : `Back to game` }}
        </button>
        <div class="notice text-center" v-show="showLoginNotice" @click="showLoginNotice=false">
          <span><font-awesome-icon icon="times"/></span>
          <h2>{{ lang === 'he' ? 'התחברת בהצלחה' : 'Successfully logged in' }}</h2>
          <p>{{ lang === 'he' ? 'נא להמתין לשאלה הבאה' : 'Please wait for the next question' }}</p>
        </div>
        <h1>
          <span v-if="leaders.length">{{ lang === 'he' ? `לוח תוצאות` : `Leaders board` }}</span>
          <span v-else>{{ lang === 'he' ? `מחוברים` : `Online` }}</span>&nbsp;
          <font-awesome-icon icon="users"/>
          <span>{{ players.length }}</span>
        </h1>
        <div class="groups-container" v-if="quiz && quiz.groups.length>1">
          <button @click="selectGroup(i)" :class="['btn-'+(i+1)]" :key="'btn-'+(i+1)" v-for="(g,i) in quiz.groups">{{
              g
            }}
          </button>
        </div>
        <div class="leaders-list">
          <div v-for="(l,k) in limitLeaders" class="leaders-item flex-apart" :key="'leader-'+k"
               :style="{direction: (lang==='he'? 'rtl':'ltr')}">
            <h4>{{ l.name }}</h4>
            <div>
              <label class="btn btn-primary">
                #<span>{{ leaders.length ? (k + 1) : (l.controllerId) }}</span>
              </label>
              <label v-if="!quiz.settings.hideLeadersMobile" class="btn btn-success me-2">
                <font-awesome-icon icon="trophy"></font-awesome-icon>&nbsp;<span>{{ l.score }}</span>
              </label>
            </div>
          </div>
        </div>
        <a v-if="question.num>=quiz.questions.length-1 && myRank<10 && mail" class="leader-email"
           :style="{direction:lang==='he'?'rtl':'ltr'}"
           :href="mail" target="_blank">{{
            lang === 'he' ? `נכנסת
        לעשירייה הפותחת! שלח מייל למנחה.` : `You're in the top 10! Email us.`
          }}</a>
      </div>
      <div v-if="currSt==='END'" class="screen screen-end" :style="{direction:lang==='he'?'rtl':'ltr'}">
        <h2 :class="{'text-right':lang==='he'}">{{ lang === 'he' ? "סוף משחק" : "Game Over" }}</h2>
        <h1 :class="{'text-right':lang==='he'}">{{ quiz.name }}</h1>
        <h3 v-if="!quiz.settings.challengeHideScore" class="text-center"><span>{{
            lang === 'he' ? "הניקוד שלכם - " : "Your score - "
          }}</span>&nbsp;<strong style="color: gold">{{ score }}</strong></h3>
        <div class="player-details-container text-center">
          <input v-if="quiz.settings.allowIdentifier" type="text" required
                 :placeholder="quiz.settings.identifierPlaceholder"
                 :class="{disabled: !quiz || !quiz.approved}"
                 maxlength="32" v-model="player.identifier"/>
          <input v-if="!quiz.settings.challengeAnonymous" type="text" style="text-transform: capitalize;"
                 :placeholder="lang==='he'?'שם מלא':'Your name'"
                 :class="{disabled: !quiz || !quiz.approved}"
                 maxlength="12" v-model="player.name"/>
        </div>
        <div class="text-container">
          <div class="text-area" :class="{'text-right':lang === 'he','text-left':lang !== 'he'}"
               v-html="quiz.settings.challengeMessageEnd"></div>
        </div>
        <div class="button-container text-center"
             :class="{'bg-green':!quiz || !quiz.settings.themeColor, disabled:sendingChallengeResults || challengeScoreAccepted}"
             :style="{'background-color':quiz &&quiz.settings.themeColor?quiz.settings.themeColor:''}"
             @click="sendChallengeResults">
          <span
              :style="{direction:lang==='he'?'rtl':'ltr', 'color':quiz &&quiz.settings.themeColor2?quiz.settings.themeColor2:''}">{{
              lang === 'he' ? 'שגר תוצאות' : 'Send Score'
            }}
            <font-awesome-icon icon="paper-plane"/>
          </span>
        </div>
      </div>
      <div v-if="currSt==='FEEDBACK'" class="screen screen-feedback">
        <iframe
            src="https://docs.google.com/forms/d/e/1FAIpQLSdLc7EMcr_xnV7KlCIwmKfoHL8QMgovuT41-unoaTILEyfIMA/viewform?embedded=true"
            width="100%" height="100%" frameborder="0" marginheight="0" marginwidth="0">בטעינה…
        </iframe>
      </div>
      <transition name="slide">
        <div class="speech" :style="{direction:lang==='he'?'rtl':'ltr'}">
          <div class="speech-bubble" v-if="announce">
            <h3 :class="{'text-right':lang==='he' }">{{ announce }}</h3>
          </div>
        </div>
      </transition>
    </div>
  </main>
  <DialogsWrapper/>
</template>
<script>
/* eslint-disable vue/no-side-effects-in-computed-properties */
const apiUrl = 'https://quizy.show/api.php';
const url = 'wss://quizygame.com/wss/';
import md5 from "blueimp-md5";
import axios from "axios";
import AvatarCropper from "vue-avatar-cropper";
import Pin from "../components/Pin";
import Sidebar from "../components/Sidebar";
import ModalVerify from "../components/ModalVerify";
import ModalCode from "../components/ModalCode";
import useConfirmBeforeAction from './../composables/useConfirmBeforeAction'

import videoSt from '@/assets/vid/st.mp4';
import soundQuizCorrect from '@/assets/sound/quiz-correct.mp3';
import soundQuizWrong from '@/assets/sound/quiz-wrong.mp3';
import soundStart from '@/assets/sound/start.mp3';
import soundQuizTheme from '@/assets/sound/quiz-theme.mp3';
import soundClick from '@/assets/sound/click.mp3';
import soundReward from '@/assets/sound/reward.mp3';
import imgBackground from '@/assets/img/background.jpeg';
import imgSoundOn from '@/assets/img/sound-on.png';
import imgSoundOff from '@/assets/img/sound-off.png';
import imgAvatar from '@/assets/img/avatar-placeholder.png';
import imgCam from '@/assets/img/camera.png';

export default {
  name: 'RemoteView',
  props: ['urlCode'],
  components: {AvatarCropper, Pin, Sidebar, ModalVerify, ModalCode},
  data() {
    return {
      buzzAnswer: '',
      init: true,
      code: "",
      codeVerified: false,
      currSt: "LOGIN",
      hashcode: "",
      clientId: "",
      challengeQuestion: 0,
      challengeSelect: null,
      challengeScore: {},
      challengeGamePlay: {},
      challengeScoreAccepted: false,
      challengeTimer: null,
      defAssets: {},
      url: "",
      player: {
        name: "",
        email: "",
        image: "",
        group: 0,
        identifier: "",
        verified: false,
      },
      conn: "",
      connOpen: false,
      connectCountdown: 5,
      gameCountdown: 0,
      lang: 'en',
      noMoreSpace: false,
      quiz: null,
      isConnected: false,
      isDebug: false,
      isProtected: false,
      isPinLocked: false,
      isPinWrong: false,
      isVerified: false,
      isSilentRetry: true,
      played: false,
      protectionType: 'text',
      game: {
        clickReceived: -1,
        allowPlay: false,
        ready: false,
        end: false,
        timePress: 0,
        correct: -1,
        wait: false,
      },
      question: {
        num: 0,
        image: null,
        video: null,
        audio: null,
        type: 'quiz',
        ans: []
      },
      announce: '',
      announceTimeout: 0,
      score: 0,
      sidebar: false,
      leaders: [],
      players: [],
      showCodeModal: false,
      showVerifyModal: false,
      showLoginNotice: false,
      sendingChallengeResults: false,
      videoMute: true,
    }
  },
  mounted() {
    this.isDebug = window.location.href.indexOf('localhost') > -1;
    this.initFromStorage();
    this.doConnectCountdown();
    this.defAssets = {
      imgAvatar,
      imgSoundOn,
      imgSoundOff,
      imgCam,
      imgBackground,
      soundQuizCorrect,
      soundStart,
      soundQuizTheme,
      soundQuizWrong,
      soundClick,
      soundReward
    };

    let self = this;

    window.addEventListener('keyup', function (ev) {
      switch (ev.key) {
        case "Enter":
          if (self.question.kind !== 'content' && self.question.type === 'buzzer' && !self.quiz.settings.buzzAnswer)
            self.sendNumber('Rush'); // declared in your component methods
          break;
      }
    });
  },
  methods: {
    sendBuzzAnswer(answer) {
      this.game.wait = true;
      this.conn.send(JSON.stringify({"action": "buzzAnswer", "answer": answer}));
    },
    tick() {
      if (!this.game.allowPlay) {
        return;
      } else if (this.gameCountdown < 1) {
        //todo stop
        if (this.quiz.type === 'challenge') {
          this.endChallengeQuestion();
          if (this.challengeTimer) clearTimeout(this.challengeTimer);
          if (this.question.kind !== 'content' || !!this.question.video) {
            this.challengeTimer = setTimeout(() => {
              this.nextChallengeQuestion()
            }, 3000);
          }
        }
      } else if (this.question.kind !== 'content') {
        this.gameCountdown--;
        if (this.gameCountdown === 4)
          document.getElementById('tickSound').play();
        setTimeout(this.tick, 1000);
      }
    },
    videoEnded() {
      if (this.question.kind === 'content') {
        if (this.isDebug) console.log(this.question);
        this.nextChallengeQuestion()
      }
    },
    handleUploaded(resp) {
      if (resp.status) {
        this.player.image = resp.url;
        //TODO update the game
        this.updateProfile();
        this.setCookie("clientImage", this.player.image);
      }
    },
    setCookie(cname, val) {
      // let d = new Date();
      // d.setTime(d.getTime() + (7 * 24 * 60 * 60 * 1000));
      // let expires = "expires=" + d.toUTCString();
      // document.cookie = cname + "=" + val + ";" + expires + ";path=/";
      localStorage.setItem(cname, val);
    },
    selectGroup(g) {
      this.sound("soundClick");
      this.player.group = g;
      this.updateProfile();
    },
    getCookie(cname) {
      // let name = cname + "=";
      // const ca = decodeURIComponent(document.cookie).split(';');
      // for (let i in ca) {
      //   const c = ca[i].trim();
      //   if (c.indexOf(name) === 0) {
      //     return c.substring(name.length, c.length);
      //   }
      // }
      // return null;
      return localStorage.getItem(cname);
    },
    setScore(isLeaders) {
      let lis = isLeaders ? this.leaders : this.players;
      for (const l of lis) {
        if (Number(l.controllerId) === Number(this.clientId)) {
          this.score = l.score;
          this.$forceUpdate();
          return;
        }
      }
    },
    removeCookie(cname) {
      // document.cookie = cname + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      localStorage.removeItem(cname);
    },
    sound(name, isURL) {
      try {
        if (isURL) {
          (new Audio(name)).play();
        } else {
          (new Audio(this.defAssets[name])).play();
        }
      } catch (e) {
        console.log(e);
      }
    },
    setImage(img) {
      this.question.image = null;
      this.question.video = null;
      this.question.audio = null;

      let t = this.getFileType(img);

      if (t === "mp3" || t === "wav")
        this.question.audio = "https://www.quizygame.com/" + img;
      else if (t === "mp4")
        this.question.video = "https://www.quizygame.com/" + img;
      else if (t === "png" || t === "gif" || t === "jpg" || t === "jpeg")
        this.question.image = "https://www.quizygame.com/" + img;
    },
    getFileType(filename) {
      if (typeof filename !== 'string') return "";
      let fileNameArr = filename.split(".");
      if (!fileNameArr.length)
        return "";
      else
        return fileNameArr[fileNameArr.length - 1].toLocaleLowerCase();
    },
    connectToServer() {
      let self = this;
      this.conn = new WebSocket(url);
      this.conn.onopen = function () {
        self.connOpen = true;
        self.isSilentRetry = true;
        self.sendCodeRoom();
        // console.log(e);
      };
      this.conn.onmessage = function (e) {
        let data = JSON.parse(e.data);
        if (data.action === "connectedToRoom") {
          self.isConnected = true;
          setTimeout(self.keepAlive, 30000);
          self.clientId = data.controllerId;
          self.hashcode = data.hashcode;
          //save
          self.storeClient();
          if (data.start) {
            self.isProtected = false;
            self.protectionType = 'text';
            self.showVerifyModal = false;
            self.start();
          }
        } else if (data.action === "wrongPin") {
          self.isPinWrong = true;
        } else if (!self.noMoreSpace && data.action === "notSpaceInRoom" && self.player.name) {
          useConfirmBeforeAction(
              {
                rtl: self.lang === 'he',
                question: self.lang === 'he' ? "לא נותר מקום בשעשעון. נסו שנית מאוחר יותר." : "There are no more seats left in the game, please try again later."
              },
              () => {
                console.log('notSpaceInRoom');
              }
          )
        } else if (data.action === "notConnectedToRoom") {
          self.codeVerified = false;
          self.isConnected = false;
        } else if (data.action === "roomProtected") {
          self.isProtected = true;
          self.protectionType = "email";
        } else if (data.action === "clickReceived") {
          if (self.question.type === 'buzzer' && self.quiz.settings.buzzAnswer) {
            /*
            -1 default, before game
             0 wrong answer
             1 correct answer, lost on time
             2 correct, winner
             */
            self.game.correct = Number(data.number);
            if (self.game.correct === 0) {
              setTimeout(() => {
                if (self.gameCountdown > 0) {
                  console.log("Try again!")
                  self.game.allowPlay = true;
                  self.game.wait = false;
                }
              }, 1000);
            }
          } else {
            self.game.clickReceived = Number(data.number) - 1;
            self.game.timePress = self.gameCountdown;
          }
        } else if (data.action === "discover") {
          if (self.currSt === 'LOGIN') return; // fix - dont skip the login screen
          //reset
          self.resetQuestion();
          //call
          setTimeout(function () {
            self.nextQuestion(data);
          }, 100);
          setTimeout(function () {
            self.game.ready = true;
          }, 400);
        } else if (data.action === "roomClosed") {
          if (self.clientId) {
            useConfirmBeforeAction(
                {
                  rtl: self.lang === 'he',
                  question: self.lang === 'he' ? "לא הצלחנו להתחבר, תרצו לנסות שנית?" : "We couldn't connect, would you like to try again?"
                },
                () => {
                  // self.removeCookie('clientId');
                  // self.removeCookie('hashcode');
                  window.location.reload();
                }, () => {
                  setTimeout(() => {
                    window.location.reload();
                  }, 2000);
                }
            )
          } else {
            useConfirmBeforeAction(
                {rtl: self.lang === 'he', question: self.lang === 'he' ? "המשחק סגור כרגע." : "The game is over."}
            )
          }
        } else if (data.action === "startGame") {
          self.game.allowPlay = true;
          self.gameCountdown = data.time_left;
          self.tick();
        } else if (data.action === "endGame") {
          self.leaders = data.leaders;
          self.gameCountdown = 0;
          self.game.allowPlay = false;
          self.game.end = true;
          self.setScore(true);
        } else if (data.action === "announce") {
          self.sound('soundReward');
          self.announce = data.announce;
          self.announceTimeout = 6;
          self.announceTick();
        } else if (data.action === 'reveal') {
          self.game.end = true;
          self.game.allowPlay = false;
          self.checkScore();
        } else if (data.action === "showLeaders") {
          if (self.currSt !== "LOGIN") {
            if (data.showLeaders)
              self.currSt = "LEADERS";
            else self.currSt = "CONTROLLER";
          }
        } else if (data.action === "showFeedback") {
          self.currSt = 'FEEDBACK';
        } else if (data.action === "updatePlayers") {
          if (data.players) {
            self.players = Object.values(data.players);
            if (self.init) {
              self.init = false;
              self.setScore();
            }
          }
        } else if (data.action === 'clientPong') {
          console.log("Keeping connection alive!")
        }
      };
      this.conn.onclose = function (e) {
        self.disconnectedFromServer();
        if (this.isSilentRetry) {
          self.isSilentRetry = false;
          self.sendCodeRoom();
        } else {
          useConfirmBeforeAction(
              {
                rtl: self.lang === 'he',
                question: self.lang === 'he' ? 'נותקת מהמשק. ננסה להתחבר מחדש!' : 'Connection lost. We will try to reconnect.'
              },
              () => {
                window.location.reload();
              }
          )
        }
        console.log(e);
      };
    },
    verifyDistance(loc1, loc2, r) {
      return this.getDistanceFromLatLonInKm(loc1.latitude, loc1.longitude, loc2.latitude, loc2.longitude) < (r / 1000);
    },
    getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
      let R = 6371; // Radius of the earth in km
      let dLat = this.deg2rad(lat2 - lat1);  // deg2rad below
      let dLon = this.deg2rad(lon2 - lon1);
      let a =
          Math.sin(dLat / 2) * Math.sin(dLat / 2) +
          Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) *
          Math.sin(dLon / 2) * Math.sin(dLon / 2)
      ;
      let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      return R * c; // Distance in km
    },
    deg2rad(deg) {
      return deg * (Math.PI / 180)
    },
    connectChallenge() {
      //get play token
      if (this.quiz.settings.challengeGeoEnabled && this.quiz.settings.challengeGeo) {
        //check physical location
        if (navigator.geolocation) {
          let geo = this.quiz.settings.challengeGeo.split(',');
          let location = {
            latitude: Number(geo[0].trim()),
            longitude: Number(geo[1].trim())
          }
          navigator.geolocation.getCurrentPosition(position => {
            this.isConnected = this.verifyDistance(location, position.coords, 75);
          });
        } else {
          this.quiz.name = "דפדפן שלכם אינו תומך בשירותי מיקום. האתגר נעול.";
        }
      } else {
        this.isConnected = true;
      }
      //new sms protection
      this.isProtected = this.quiz.settings.challengeVerifySMS;
      if (this.isProtected) this.protectionType = 'tel';
    },
    nextQuestion(data) {
      if (this.isDebug) console.log('nextQuestion', data);

      this.buzzAnswer = '';
      if (data.num > -1 && data.num < this.quiz.questions.length)
        this.question = this.quiz.questions[data.num];

      this.question.num = data.num;

      if (this.quiz.type === 'trivia' || this.quiz.settings.numericAnswer)
        this.question.ask = data.Q;

      if (this.quiz.type === 'live')
        this.setImage(this.question.img);
      //association
      if (data.type) {
        this.question.type = "buzzer";
      } else {
        this.question.type = "quiz";
      }
    },
    resetQuestion() {
      if (this.currSt !== 'LOGIN' && this.currSt !== 'CONTROLLER')
        this.currSt = 'CONTROLLER';

      document.getElementById('tickSound').pause();
      document.getElementById('tickSound').currentTime = 0;

      this.question = {
        num: 0,
        image: null,
        video: null,
        audio: null,
        ans: []
      };
      this.game = {
        clickReceived: -1,
        allowPlay: false,
        ready: false,
        end: false,
        timePress: 0,
        correct: -1
      }

      this.challengeSelect = null;

    },
    announceTick() {
      if (this.announceTimeout > 1) {
        this.announceTimeout--;
        setTimeout(this.announceTick, 1000);
      } else
        this.announce = '';
    },
    doConnectCountdown() {
      this.connectCountdown--;
      if (this.quiz && this.connectCountdown < 1) {
        if (this.quiz.type === 'challenge') {
          this.connectChallenge();
        } else {
          this.sendCodeRoom();
        }
        this.connectCountdown = 5;
      }
      if (!this.isConnected && !this.isProtected) setTimeout(this.doConnectCountdown, 1000);
    },
    start() {
      this.storePlayer();
      if (this.isProtected && (!this.isConnected || !this.isVerified)) {
        if (this.pin) {
          this.checkPin(this.pin, true);
        } else {
          this.newPin(true);
          this.showVerifyModal = true;
        }
      } else {
        (new window.NoSleep()).enable();
        this.showLoginNotice = true;
        if (location.hostname !== "localhost" && window.screen.height > window.screen.width)
          this.launchIntoFullscreen(document.documentElement);
        this.sound('soundSuccess');
        this.setCookie("roomCode", this.code);
        if (this.quiz.type === "challenge") {
          this.currSt = "INTRO";
        } else {
          this.sendName(this.player.name, this.player.image, this.player.email, this.player.group);
          this.currSt = "LEADERS";
        }
      }
    },
    checkPin(pin, resetOnFail = false) {
      //if sms pin
      if (this.protectionType === 'tel') {
        let options = {
          headers: {
            action: "verifyChallengePlayerSMS",
          }
        }
        let url = new URL(apiUrl);
        url.searchParams.append("tel", this.player.identifier);
        url.searchParams.append("pin", pin);
        url.searchParams.append("quiz_id", this.quiz.id);
        axios.get(url.href, options)
            .then(response => {
              if (response.data.status) {
                //save the pin
                this.setCookie("clientPin", pin);
                this.isVerified = true;
                this.showVerifyModal = false;
                this.start();
              } else if (response.data.played) {
                this.played = true;
                this.showVerifyModal = true;
              } else {
                this.isPinWrong = true;
                if (resetOnFail) {
                  this.pin = '';
                  this.newPin();
                  this.showVerifyModal = true;
                }
              }
            })
      }
      //if live email
      else if (this.protectionType === 'email') {
        let data = {
          "action": "connectWithEmail",
          "code": this.code,
          "email": this.player.email,
          "pin": pin,
        }
        if (this.connOpen)
          this.conn.send(JSON.stringify(data));
      }
    },
    newPin(onlyNew) {
      this.isPinLocked = true;
      setTimeout(() => {
        this.isPinLocked = false;
      }, 10000);

      //split between websocket (email) and api (sms)
      if (this.protectionType === 'tel' && this.player.identifier) {
        let options = {
          headers: {
            action: "setChallengePlayerSMS",
          }
        }
        let url = new URL(apiUrl);
        url.searchParams.append("tel", this.player.identifier);
        url.searchParams.append("name", this.player.name);
        url.searchParams.append("quiz_id", this.quiz.id);
        axios.get(url.href, options)
            .then(response => {
              //console.log(response);
              if (response.data.status) {
                //proceed to pin screen
              } else if (response.data.played) {
                this.played = true;
                this.showVerifyModal = true;
              }
            })
      } else if (this.protectionType === 'email' && this.player.email) {
        let data = {
          "action": "newPin",
          "code": this.code,
          "email": this.player.email,
          "onlyNew": !!onlyNew
        }
        if (this.connOpen)
          this.conn.send(JSON.stringify(data));
      }
    },
    storeClient() {
      //  console.log("Store", this.hashcode, this.clientId)
      if (this.hashcode && this.hashcode !== 'null')
        this.setCookie('hashcode', this.hashcode);
      if (this.clientId && this.clientId !== 'null')
        this.setCookie('clientId', this.clientId);
      this.codeVerified = true;
    },
    storePlayer() {
      if (this.player.name)
        this.setCookie("clientName", this.player.name);
      if (this.player.email)
        this.setCookie("clientEmail", this.player.email);
      if (this.player.image)
        this.setCookie("clientImage", this.player.image);
      if (this.player.group)
        this.setCookie("clientGroup", this.player.group);
      if (this.player.identifier)
        this.setCookie("identifier", this.player.identifier);
    },
    disconnectedFromServer() {
      this.currSt = "LOGIN";
      this.connOpen = false;
      this.isConnected = false;
      this.showVerifyModal = false;
      this.showCodeModal = false;
      this.doConnectCountdown();
    },
    sendCodeRoom() {
      let data = {
        "action": "connectToRoom",
        "code": this.code,
        "clientId": this.clientId,
        "hashcode": this.hashcode
      };
      if (this.connOpen)
        this.conn.send(JSON.stringify(data));
    },
    sendNumber(char, num) {
      if (this.quiz.type === 'challenge') {

        if (this.quiz.settings.challengeSingleClick) {
          this.sound("soundClick");
          this.challengeSelect = num;
        }

        if (this.challengeSelect === num) {
          //2nd select = submit
          //TODO stop timer
          this.game.timePress = this.gameCountdown;
          this.game.clickReceived = num;
          this.endChallengeQuestion();
          if (this.challengeTimer) clearTimeout(this.challengeTimer);
          this.challengeTimer = setTimeout(() => {
            this.nextChallengeQuestion()
          }, 2500);
        } else {
          this.sound("soundClick");
          this.challengeSelect = num;
        }
      } else {
        if (this.quiz.settings.allowChangeChoice || this.game.clickReceived === -1) {
          this.game.clickReceived = num;
          this.conn.send(JSON.stringify({"action": "sendNumber", "number": char}));
          this.sound("soundClick");
        }
      }
    },
    sendName(name, profile, email = '', group = 0) {
      this.conn.send(JSON.stringify({
        "action": "sendName",
        "name": name,
        "profile": profile,
        "email": email,
        "group": group
      }));
    },
    updateProfile() {
      this.conn.send(JSON.stringify({
        "action": "updateProfile",
        "clientId": this.clientId,
        "name": this.player.name,
        "profile": this.player.image,
        "group": this.player.group,
        "email": this.player.email
      }));
    },
    keepAlive() {
      if (this.isConnected) {
        this.conn.send(JSON.stringify({
          "action": "clientPing",
          "clientId": this.clientId,
          "hash": this.hashcode
        }));
        setTimeout(this.keepAlive, 20000);
      }
    },
    launchIntoFullscreen(element) {
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      }
    },
    updateCode(newCode) {
      this.code = newCode;
      this.checkCode();
    },
    checkCode() {
      if (this.code.toString().length === 4) {
        this.getQuiz();
      } else {
        this.currSt = "LOGIN";
        this.quiz = null;
      }
    },
    checkScore() {
      if (this.question.kind === "regular") {
        let flag = false;
        for (let x = 0; x < 6; x++) {
          if (md5(this.question.num + '-' + x) === this.question.corr) {
            this.game.correct = x;
            if (this.game.clickReceived === this.game.correct && this.question.time !== 0) {

              if (this.quiz.type === 'challenge') {
                console.log("calc", this.question.score, this.game.timePress, this.question.time);
                let s = Math.ceil(this.question.score * this.game.timePress / this.question.time);
                if (!isNaN(s)) {
                  this.challengeScore[this.question.num] = s;
                  this.score = Object.values(this.challengeScore).reduce((a, b) => a + b, 0);
                }
              }

              if (this.quiz.settings.soundCorrect)
                this.sound(this.quiz.settings.soundCorrect, true);
              else
                this.sound("soundQuizCorrect");

              flag = true;
              break;
            }
          }
        }
        if (!flag) {
          if (this.quiz.settings.soundWrong)
            this.sound(this.quiz.settings.soundWrong, true);
          else
            this.sound("soundQuizWrong");
        }
      } else if (this.question.kind === "survey") {
        this.game.correct = this.game.clickReceived;
        if (this.quiz.settings.soundCorrect)
          this.sound(this.quiz.settings.soundCorrect, true);
        else
          this.sound("soundQuizCorrect");
      }
    },
    decode(str) {
      return decodeURIComponent(escape(str));
    },
    getQuiz() {
      let options = {
        headers: {
          action: "getQuiz",
        }
      };
      axios
          .get(apiUrl + "?quizCode=" + this.code.toUpperCase(), options)
          .then(response => {
            if (response.status) {
              this.setCookie("roomCode", this.code);
              let q = response.data.data;
              if (q) {
                q.questions = typeof q.questions === "object" ? q.questions : JSON.parse(q.questions);
                q.settings = typeof q.settings === "object" ? q.settings : JSON.parse(q.settings);
                q.groups = !q.groups ? [] : typeof q.groups === "object" ? q.groups : JSON.parse(q.groups);
                q.groups = Array.isArray(q.groups) ? q.groups : [];
                if (this.player.group > q.groups.length)
                  this.player.group = 0;

                this.quiz = q;

                // if (Array.isArray(this.quiz.questions)) {
                //   this.quiz.questions.map(q => {
                //     if (q.kind === "association") {
                //       //q.ans[0].opt2 = this.decode(q.ans[0].opt);
                //       if (this.isDebug) console.log(q.ans[0].opt2);
                //     } else if (q.kind === "content") {
                //       //todo implement
                //     }
                //     return q;
                //   })
                // }

                this.lang = q.settings.lang;
                this.preloadMedia();

                if (this.quiz.type !== "challenge") {
                  this.connectToServer();
                } else {
                  this.connectChallenge();
                }
              } else {
                this.quiz = null;
              }
            }
          })
    },
    initFromStorage() {
      let cookieCode = this.getCookie("roomCode");
      if (this.urlCode && this.urlCode.length === 4 && !cookieCode) {
        this.code = this.urlCode.toUpperCase();
        this.setCookie("roomCode", this.code);
      } else if (this.urlCode && this.urlCode.length === 4 && typeof cookieCode === 'string' && this.urlCode.toUpperCase() !== cookieCode.toUpperCase()) {
        this.code = this.urlCode.toUpperCase();
        this.setCookie("roomCode", this.code);
        this.removeCookie("clientId");
        this.removeCookie("hashcode");
        this.removeCookie("clientPin");
        // console.log("Remove cookie");
      } else {
        this.code = this.getCookie("roomCode");
        this.clientId = this.getCookie("clientId");
        this.hashcode = this.getCookie("hashcode");
      }
      this.player.name = this.getCookie("clientName") && this.getCookie("clientName") !== 'null' ? this.getCookie("clientName") : ''
      this.player.email = this.getCookie("clientEmail") && this.getCookie("clientEmail") !== 'null' ? this.getCookie("clientEmail") : '';
      this.player.image = this.getCookie("clientImage") && this.getCookie("clientImage") !== 'null' ? this.getCookie("clientImage") : '';
      this.player.identifier = this.getCookie("identifier") ? this.getCookie("identifier") : '';
      this.pin = this.getCookie("clientPin") ? this.getCookie("clientPin") : '';
      this.player.group = this.getCookie("clientGroup") ? parseInt(this.getCookie("clientGroup")) : 0;
      this.checkCode();
    },
    preloadMedia: function () {
      let self = this;

      let internal = [soundStart, videoSt, soundQuizTheme];
      if (this.quiz.settings.soundCorrect) {
        internal.push(this.quiz.settings.soundCorrect)
      } else {
        internal.push(soundQuizCorrect);
      }
      if (this.quiz.settings.soundWrong) {
        internal.push(this.quiz.settings.soundWrong)
      } else {
        internal.push(soundQuizWrong);
      }
      let preload = function (url) {
        let req = new XMLHttpRequest();
        let x = url.split("/");
        self.loadedFile = x[x.length - 1];
        req.open('GET', url, true);
        req.responseType = 'blob';
        req.onload = function () {
          if (this.status === 200) {
            let videoBlob = this.response;
            URL.createObjectURL(videoBlob); // IE10+
          }
        };
        req.send();
      };
      internal.forEach(preload);
    },
    startChallenge: function () {
      this.currSt = "CONTROLLER";
      this.nextChallengeQuestion(true);
    },
    nextChallengeQuestion: function (isInit) {

      this.resetQuestion();
      this.buzzAnswer = '';

      if (!isInit)
        this.challengeQuestion++;

      if (this.challengeQuestion > -1 && this.challengeQuestion < this.quiz.questions.length) {
        this.question = this.quiz.questions[this.challengeQuestion];
      } else {
        this.currSt = "END";
      }

      this.question.num = this.challengeQuestion;
      this.setImage(this.question.img);

      if (this.isDebug) console.log(this.question);
      //start
      this.game.ready = true;
      this.gameCountdown = this.question.time;
      this.game.end = false;
      this.game.allowPlay = true;
      this.game.wait = false;
      this.tick();
    },
    endChallengeQuestion() {
      document.getElementById('tickSound').pause();
      //end game
      this.game.allowPlay = false;
      this.game.end = true;
      //save answer chosen
      this.challengeGamePlay[this.question.num] = (this.challengeSelect !== null ? this.challengeSelect + 1 : '');
      //reveal
      this.checkScore();
      this.gameCountdown = 0;

    },
    sendChallengeResults() {
      this.sendingChallengeResults = true;
      let options = {
        headers: {
          action: "setChallengeResults",
          "content-type": "application/json"
        }
      };
      let data = {
        name: this.player.name,
        group: this.quiz.groups.length > 1 && this.player.group > 0 ? this.quiz.groups[this.player.group] : "",
        identifier: this.quiz.settings.allowIdentifier ? this.player.identifier : null,
        score: this.score,
        quiz_id: this.quiz.id,
        data: JSON.stringify(this.challengeScore),
        gamePlay: JSON.stringify(this.challengeGamePlay)
      }
      axios.post(apiUrl, data, options)
          .then(response => {
            this.sendingChallengeResults = false;
            if (response.status) {
              this.challengeScoreAccepted = true;
              useConfirmBeforeAction(
                  {rtl: self.lang === 'he', question: this.lang === 'he' ? "הניקוד התקבל!" : "We got your results!"},
                  () => {
                    if (this.quiz.settings.helpUrl)
                      window.open(this.quiz.settings.helpUrl, "_self");
                  }
              )
            } else {
              useConfirmBeforeAction(
                  {
                    rtl: self.lang === 'he',
                    question: this.lang === 'he' ? "לצערנו עקב תקלה הניקוד לא התקבל." : "Unfortunately due to an error we couldn't receive the results."
                  },
                  () => {
                  }
              )
            }
          }).catch(() => {
        this.sendingChallengeResults = false;

        useConfirmBeforeAction(
            {
              rtl: self.lang === 'he',
              question: this.lang === 'he' ? "לא הצלחנו לשדר את התוצואת, ננסה שוב בעוד 5 שניות!" : "We didn't manage to save the score, trying again in 5s."
            },
            () => {
            }
        )
        setTimeout(this.sendChallengeResults, 5000);
      })
    },
    isVideo(url) {
      if (!url) return false;
      const videoRegex = /\.(mp4|avi|mov|flv|wmv|mkv)$/i;
      return videoRegex.test(url);
    }
  },
  computed: {
    startText() {
      if (this.quiz && this.quiz.settings.mobileProtect && !this.isConnected)
        return this.lang === 'he' ? 'הרשמו עכשיו' : 'Register Now';
      else if (this.quiz && this.quiz.settings.challengeVerifySMS)
        return this.lang === 'he' ? 'שלחו לי קוד אימות' : 'Send me verification code';
      else
        return this.lang === 'he' ? 'התחל!' : 'Start!';
    },
    showName() {
      if (!this.quiz) {
        return this.lang === 'he' ? 'בחרו שעשעון' : 'Choose your Quiz';
      } else if (this.quiz.type === 'challenge' && this.quiz.settings && this.quiz.settings.challengeGeoEnabled) {
        return this.isConnected ? this.quiz.name : this.quiz.settings.challengeGeoError
      } else {
        return this.quiz.name
      }
    },
    currTimeGone() {
      if (this.question.time === 0) return "0%";
      return Math.round(100 * (this.question.time - this.gameCountdown) / this.question.time) + "%";
    },
    currTimeLeft() {
      if (this.question.time === 0) return "0%";
      return Math.round(100 * this.gameCountdown / this.question.time) + "%";
    },
    verifiedEmail() {
      const re = /\S+@\S+\.\S+/;
      if (!this.player.email) return false;
      else return re.test(this.player.email);
    },
    allowStart() {
      if (!this.quiz || (!this.nameVerified && !this.quiz.settings.challengeAnonymous)) return false;
      if (this.quiz.type === "challenge") {
        if (!this.isConnected) return false;
        if (this.quiz.groups.length > 1 && !this.player.group) return false;
        if (this.quiz.settings.allowIdentifier && this.player.identifier.length < 3) return false;
        return !(this.quiz.settings.challengeVerifySMS && this.player.identifier.length !== 10);

      } else {
        return (this.isProtected && this.verifiedEmail) || (this.clientId && this.isConnected);
      }
    },
    bgImage() {
      if (this.quiz && this.isVideo(this.quiz.background))
        return '';
      else if (this.quiz && this.quiz.background)
        return "https://www.quizygame.com/" + this.quiz.background;
      else return imgBackground;
    },
    bgVideo() {
      if (this.quiz && this.isVideo(this.quiz.background))
        return "https://www.quizygame.com/" + this.quiz.background;
      else return '';
    },
    nameVerified() {
      if (!this.player.name) return false;
      return this.player.name.length < 13 && this.player.name.length > 2;
    },
    // eslint-disable-next-line
    limitLeaders() {
      if (this.leaders.length)
        return this.leaders.slice(0, Math.min(this.leaders.length, 10));
      else
        return this.players.sort(function (a, b) {
          // eslint-disable-next-line
          return (b.controllerId > a.controllerId) ? 1 : (b.controllerId === a.controllerId ? 0 : -1);
        });
    },
    myRank() {
      if (!this.leaders) return 99;
      let id = this.controllerId;
      let rank = this.leaders.length;
      for (let i = 0; i < this.leaders.length; i++) if (this.leaders[i].controllerId === id) {
        rank = i + 1;
        break;
      }
      return rank;
    },
    mail() {
      if (!this.quiz.email) return null;
      let subject = `Leader #${this.myRank} from game ${this.quiz.name}`;
      let body = `Name: ${this.player.name}
      Player: #${this.clientId}`;
      return `mailto:${this.quiz.email}?subject=${subject}&body=${body}`;
    },
  },
  watch: {
    //watch identifier change - only numbers allowed
    'player.identifier': function (val, oldVal) {
      if (this.quiz && this.quiz.settings && this.quiz.settings.challengeVerifySMS) {
        if ((/[^$,\d]/.test(val) || val.length > 10))
          this.player.identifier = oldVal;
      }
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

header {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  height: 36px;
  font-size: 20px;
  z-index: 9;
  background: rgba(0, 0, 0, 0.66);

  &.slim {
    right: 3px;
    top: 3px;
    left: unset;
    width: 38px;
    border-radius: 6px;
  }

  a {
    right: 10px;
    top: 2px;
    position: absolute;
    color: #ffffff;
  }
}

.container-fluid {
  max-width: 440px;
  position: relative;
  height: 100%;
  overflow-y: hidden;
  overflow-x: visible;
  box-shadow: 0 0 20px #000;
}

.app-overlay {
  opacity: 0.75;
  background: #111111;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 0;
}

.textBlack {
  color: #333333;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.screen {
  height: 100%;
  min-height: 420px;
  margin: auto;
  position: relative;
  overflow: hidden;

  > div {
    position: relative;
    width: 100%;
  }

  input[type="text"], input[type="email"], input[type="tel"] {
    width: 100%;
    text-align: center;
    border: none;
    outline: none;
    background: transparent !important;
    font-size: 1.75rem;
    padding: 0;
    box-shadow: none;
    height: auto;
    margin-bottom: 0;
    color: #555;
  }

  input[type="file"] {
    display: none;
    pointer-events: none;
  }
}

.half-top {
  top: 50%;
  transform: translateY(-50%);
  position: relative;
}

@media (max-height: 600px) {
  .screen-login h4 {
    display: none;
  }
}

.screen h1, .screen h3 {
  margin: 0;
}

.screen h4 {
  color: #999;
  font-size: 1rem;
  margin: 0;
}

.header {
  background: black;
  height: auto;
  padding: 40px 12px 4px;

  .header-right {
    position: relative;
  }

  h1 {
    height: 3rem;
    font-size: 30px;
    font-weight: 800;
    line-height: 48px;
    white-space: nowrap;
    margin: 0.25rem 1rem 0 0;
  }

  .text-right {
    .btn {
      margin: 0.25rem 0 0 1rem;
    }
  }

  .text-left {
    .btn {
      margin: 0.25rem 1rem 0 0;
    }
  }

  .btn {
    min-width: 68px;
    line-height: 20px;
    font-size: 28px;
    padding: 0.25rem 0.5rem;
    height: 3rem;

    small {
      font-size: 50%;
      display: block;
    }
  }
}

.screen-intro, .screen-end {
  background: rgba(0, 0, 0, 0.75);
  padding: 80px 10px 100px;

  h1, h2, h3 {
    color: whitesmoke;
  }

  h1 {
    font-size: 32px;
  }

  h3 {
    font-size: 1.75rem;
    margin: 40px 0;
  }

  .text-container {
    margin: 20px 0;

    .text-area {
      max-height: 600px;
      overflow-x: hidden;
      overflow-y: auto;
      font-size: 20px;
      padding: 10px;
      color: whitesmoke;
    }
  }

  .button-container {
    padding: 20px 10px;
    width: 100%;
    left: 0;
    bottom: 0;
    position: absolute;
    color: white;
    font-size: 2.5rem;
    cursor: pointer;
  }
}

.screen-leaders {
  background: var(--black);
  padding: 10px 12px 40px;
  overflow-y: auto;
  overflow-x: hidden;

  h1 {
    background: var(--blue);
    color: whitesmoke;
    padding: 8px 0;
    text-align: center;
    font-size: 32px;
  }
}

.leaders-list {
  margin: 10px 0;
}

.leaders-me {
  margin: 10px 0;
}

.leaders-item {
  line-height: 3.3rem;
}

.leaders-me h3 {
  color: var(--blue);
  font-size: 2rem;
  font-weight: 800;
}

.leaders-item {

  .btn {
    margin-top: 0.5rem;
  }

  h4 {
    color: var(--yellow);
    line-height: 48px;
    font-weight: 400;
    font-size: 28px;
    white-space: nowrap;
  }

}

.leaders-item:nth-child(even) {
  background: #202326;
}

.leader-email {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  font-size: 28px;
  line-height: normal;
  background: white;
  padding: 4px 60px;
  color: var(--blue);
}

.screen-login {
  background: rgba(0, 0, 0, 0.9);
}

.screen-login input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #CCCCCC;
}

.screen-login input::-moz-placeholder { /* Firefox 19+ */
  color: #CCCCCC;
}

.screen-login {
  .hero {
    //padding-top: 20px;
    height: 50%;
    background: whitesmoke center top/cover no-repeat;
    border-bottom: 2px solid #111;

    .hero-video {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    label {
      cursor: pointer;
    }
  }

  .email-container {
    height: calc(15% - 2px);

    &.sm {
      height: calc(10% - 2px);
      line-height: 0.9;
    }

    background: whitesmoke;
    color: #555;
    border-bottom: 2px solid #111;
  }

  .placeholder-container {
    height: 30%;
    background: #010101;
  }

  .name-container {
    height: calc(15% - 2px);
    background: whitesmoke;
    color: #555;
    border-bottom: 2px solid #111;
  }

  .id-container {
    height: calc(15% - 2px);
    background: whitesmoke;
    color: #555;
    border-bottom: 2px solid #111;
  }

  .login-container {
    border-top: 2px solid #111;
    height: calc(20% - 2px);

    &.sm {
      height: calc(15% - 2px);
    }
  }
}

.challenge {

  .header {
    height: auto;
  }
}

.groups {
  .screen-login {

    .name-container {
      height: calc(10% - 2px);

      input {
        font-size: 1.75rem;
      }
    }

    .group-container {
      height: calc(10% - 2px);
      background: whitesmoke;
      color: #555;
      border-bottom: 2px solid #111;

      select {
        font-size: 1.5rem;
        background: transparent;
        border: none;
        width: 80%;
        height: 100%;
        text-align: center;
        text-align-last: center;
        padding: 0 2rem;

        option {
          text-align: center;
        }
      }
    }

    .login-container {
      height: 15%;

      &.sm {
        height: 10%;
      }
    }
  }
}

.screen-controller {
  background-color: var(--black);

  .header > div:first-child {
    max-width: calc(100% - 80px);
    overflow: hidden;
  }

  .hero {
    height: 40%;
    background: #111111 center top/contain no-repeat;
  }

  .hero h3 {
    position: absolute;
    font-size: 24px;
    bottom: 24px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
  }

  .controller {
    height: 40%;
  }

  .hero label {
    bottom: 8px;
  }
}

.hero {

  label {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    margin-bottom: 0;
    font-size: 28px;
    line-height: 36px;
    padding: 8px;
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center top;
  }

  .video-button {
    position: absolute;
    left: 12px;
    top: 8px;
    background: transparent;
    border-radius: 100%;
    padding: 8px;
    border: none;
    filter: invert(1);
    cursor: pointer;

    img {
      width: 24px;
      height: 24px;
    }
  }
}

.camera {
  height: 58px;
  width: 58px;
  padding: 1px;
}

.profile-image {
  height: 100%;
  width: 100%;
  background-color: transparent;
  background-size: contain;
  background-repeat: no-repeat;

  &.profile-avatar {
    margin: 0.25rem auto 0 0;
    height: 48px;
    width: 48px;
    border-radius: 0.2em;
  }
}

.screen .fi-check {
  opacity: 0.2;
  position: absolute;
  right: 1rem;
  font-size: 2.5rem;
  top: 0;
  line-height: normal;
  transition: 500ms;
}

.screen .fi-check.checked {
  opacity: 1;
  color: green;
}

.camera-container {
  color: white;
  font-size: 96px;
}

.camera-container img {
  height: 96px;
  width: auto;

}

.image-input-button {
  width: 50%;
  height: 100%;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 25%;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.disabled-transparent {
  pointer-events: none;
}

.facebook-container h3 {
  color: #eeeeee;
}

.facebook-container h1 {
  margin: 0;
}

.facebook-container, .login-container {
  cursor: pointer;
}

.or-label {
  left: calc(50% - 20px);
  top: -20px;
  width: 40px;
  height: 40px;
  position: absolute;
  text-align: center;
  line-height: 40px;
  font-size: 20px;
  border: 1px solid white;
  text-transform: capitalize;
  background: rgba(200, 200, 200, 0.9); /* Old browsers */
  background: linear-gradient(to bottom, rgb(13, 124, 197) 0%, rgb(58, 201, 49) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#C772A0', endColorstr='rgb(58, 201, 49)', GradientType=0); /* IE6-9 */
  pointer-events: none;
}

.menu-buttons {
  position: absolute;
  z-index: 3;
  right: 0;
  top: 0;
  padding: 16px;
  text-align: center;
}

.menu-buttons > i {
  font-size: 32px;
  display: block;
  color: whitesmoke;
}

.background {
  background: transparent;
  background-repeat: no-repeat;
}

.bg1-1 {
  background-image: url("@/assets/img/bg1.jpg");
  background-position: center 0;
}

.bg1-2 {
  background-image: url("@/assets/img/bg1.jpg");
  background-position: center -188px;
}

.bg1-3 {
  background-image: url("@/assets/img/bg1.jpg");
  background-position: center -376px;
}

.whatsapp {
  background: transparent center/100% url("@/assets/img/whatsapp.png") no-repeat;
  position: absolute;
  right: 8px;
  top: 8px;
  padding: 24px;
}

.whatsapp > span {
  color: #444;
  transform: translate(-170%, -50%);
  position: absolute;
  font-size: 22px;
}

.button-3 {
  width: calc(33.33% - 2px);
  border: 1px solid #111111;
  display: inline-block;
  margin: 0;
  font-size: 72px;
  font-weight: 600;
  color: white;
}

.btn-1 {
  background: var(--green);
  color: white;
}

.btn-2 {
  background: var(--red);
  color: white;
}

.btn-3 {
  background: white;
  color: #111111 !important;
}

.btn-4 {
  background: var(--yellow);
  color: #111111 !important;
}

.btn-5 {
  background: var(--blue);
  color: white;
}

.btn-6 {
  background: var(--orange);
  color: white;
}

.flex-center {
  display: flex;
  justify-content: center;
}

.flex-apart {
  display: flex;
  justify-content: space-between;

}

.flex-baseline {
  align-items: flex-start;
}

.flex-start {
  display: flex;
  justify-content: flex-start;
}

.bottom-text {
  color: lightblue;
  bottom: 4px;
  font-size: 18px;
  position: absolute;
  left: 4px;
  right: 4px;
  display: block;
  text-align: center;
}

.question-container {
  background: white;
}

.question-container h3 {
  color: #0093FA;
  font-size: 32px;
}

.bg-dark {
  background: black;
  color: white;
}

.profile {
  position: absolute;
  z-index: 3;
  left: 16px;
  top: 16px;
  width: 84px;
  height: 84px;
  line-height: 84px;
  text-align: center;
  font-size: 64px;
  color: white;
  background: #0093FA;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}

.client {
  font-size: 13px;
  line-height: 15px;
  color: whitesmoke;
  position: absolute;
  background: rgba(0, 0, 0, 0.75);
  bottom: 0;
  width: 100%;
}

@media (max-width: 360px) {
  .client {
    font-size: 20px;
  }

  .header h1 {
    font-size: 24px;
  }
}

.client > svg {
  font-size: 0.8em;
}

.client .fa-trophy {
  color: var(--yellow);
}

.client .fa-user {
  color: var(--blue);
}

.client .fa-users {
  color: var(--yellow);
}

.buttons-disabled {
  /*background: rgba(168, 204, 215, 0.6);*/
  /*border: 1px solid white;*/
  border: none;
  position: absolute;
  width: 100%;
  height: 200%;
  z-index: 3;
  left: 0;
  top: -100%;
}

.buzzer-disabled {
  border: none;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 3;
  left: 0;
  top: 0;
}

.buttons-disabled > *:first-child, .buzzer-disabled > *:first-child {
  position: relative;
  display: block;
  width: 100%;
  text-align: center;
  color: #111111;
  font-weight: lighter;
  font-size: 42px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 3;
}

.buttons-disabled > *:last-child, .buzzer-disabled > *:last-child {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
}

.glass-effect {
  background: transparent url("@/assets/img/glass.png") no-repeat;
  background-size: 110% 110%;
  background-position: center;
  filter: opacity(98%);
}

.grey-out {
  color: #DDD !important;
  background: #222 !important;
  transition: none;
}

.aController {
  height: 100%;
  padding: 8px;
}

.aController button {
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 72px;
  font-weight: 600;
  color: white;
  border: none;
  direction: ltr;
}

.bg-red {
  background: var(--red);
}

.bg-green {
  background: var(--green);
}

.bg-blue {
  background: var(--blue);
}

.bg-yellow {
  background: var(--yellow);
}

.container-fluid {
  padding: 0;
}

.hidden {
  display: none;
}

.overlay {
  background: rgba(0, 0, 0, .95);
  color: white;
}

.slide-enter-active {
  transition: transform 1.5s;
}

.slide-enter, .slide-leave-to /* .fade-leave-active below version 2.1.8 */
{
  transform: translateY(100%);
}

.fill-enter-active, .fill-leave-active {
  transition: transform 1.5s;
}

.fill-enter, .fill-leave-to /* .fade-leave-active below version 2.1.8 */
{
  transform: translateY(100%);
}

.fade-in {
  transition: opacity 1.5s ease-in;
}

.fade-in:nth-child(2) {
  transition-delay: 1.5s;
}

.fade-in:nth-child(3) {
  transition-delay: 3s;
}

.fade-in:nth-child(4) {
  transition-delay: 4.5s;
}

.fade-in:nth-child(5) {
  transition-delay: 6s;
}

.fade-in:nth-child(6) {
  transition-delay: 7.5s;
}

@keyframes ldio-dpdyqlle2zf-1 {
  0% {
    transform: rotate(0deg)
  }
  50% {
    transform: rotate(-45deg)
  }
  100% {
    transform: rotate(0deg)
  }
}

@keyframes ldio-dpdyqlle2zf-2 {
  0% {
    transform: rotate(180deg)
  }
  50% {
    transform: rotate(225deg)
  }
  100% {
    transform: rotate(180deg)
  }
}

.ldio-dpdyqlle2zf > div:nth-child(2) {
  transform: translate(-15px, 0);
}

.ldio-dpdyqlle2zf > div:nth-child(2) div {
  position: absolute;
  top: 40px;
  left: 40px;
  width: 120px;
  height: 60px;
  border-radius: 120px 120px 0 0;
  background: #E5D602;
  animation: ldio-dpdyqlle2zf-1 1s linear infinite;
  transform-origin: 60px 60px
}

.ldio-dpdyqlle2zf > div:nth-child(2) div:nth-child(2) {
  animation: ldio-dpdyqlle2zf-2 1s linear infinite
}

.ldio-dpdyqlle2zf > div:nth-child(2) div:nth-child(3) {
  transform: rotate(-90deg);
  animation: none;
}

@keyframes ldio-dpdyqlle2zf-3 {
  0% {
    transform: translate(190px, 0);
    opacity: 0
  }
  20% {
    opacity: 1
  }
  100% {
    transform: translate(70px, 0);
    opacity: 1
  }
}

.ldio-dpdyqlle2zf > div:nth-child(1) {
  display: block;
}

.ldio-dpdyqlle2zf > div:nth-child(1) div {
  position: absolute;
  top: 92px;
  left: -8px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #FF3057;
  animation: ldio-dpdyqlle2zf-3 1s linear infinite
}

.ldio-dpdyqlle2zf > div:nth-child(1) div:nth-child(1) {
  animation-delay: -0.67s
}

.ldio-dpdyqlle2zf > div:nth-child(1) div:nth-child(2) {
  animation-delay: -0.33s
}

.ldio-dpdyqlle2zf > div:nth-child(1) div:nth-child(3) {
  animation-delay: 0s
}

.loadingio-spinner-bean-eater-ro8nxm7eev {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
}

.ldio-dpdyqlle2zf {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}

.ldio-dpdyqlle2zf div {
  box-sizing: content-box;
}

.flipped {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.timer {
  position: absolute;
  bottom: 0;
  height: 16px;
  border: none;
  transition: width 0.5s ease;
}

.timer-gone {
  right: 0;
  width: 0;
  background: var(--red);
  border-bottom: 2px solid #101010;
  border-top: 2px solid #101010;
}

.timer-left {
  left: 4px;
  width: calc(100% - 8px);
  background: var(--blue);
  border-bottom: 2px solid #101010;
  border-top: 2px solid #101010;
}

.speech {
  position: fixed;
  right: 25px;
  top: 150px;
  margin-left: 25px;
}

.speech-bubble {
  position: relative;
  padding: 15px;
  background: var(--green);
  border-radius: .4em;
}

.speech-bubble h3 {
  color: whitesmoke;
}

.speech-bubble:after {
  content: '';
  position: absolute;
  right: 0;
  top: 50%;
  width: 0;
  height: 0;
  border: 1.281em solid transparent;
  border-left-color: var(--green);
  border-right: 0;
  border-top: 0;
  margin-top: -0.641em;
  margin-right: -1.281em;
}

.buttons-full {
  position: relative;
  height: 100%;
  display: grid;
  grid-column: 1;
  grid-auto-rows: auto;
  overflow-y: auto;
  overflow-x: hidden;
}

.buttons-full > button {
  border: none;
}

.buttons-overlay {
  height: 100%;
  top: 0;
}

.answer {
  opacity: 0;
  font-size: 1.5rem;
  outline: none;
}

.buttons-full.play .answer {
  opacity: 1;
  transition: none;
}

.buttons-full.ready .answer.disabled-transparent {
  opacity: 0.5;
}

.buttons-full.end {
  .answer-correct {
    opacity: 1 !important;
    transition: none;
  }

  .answer-wrong {
    opacity: 0.5;
    transition: none;
  }

  .answer {
    transition: opacity 0.2s ease-in;
    opacity: 0.5;
  }
}

.answer-correct {
  background: var(--green) !important;
  color: white !important;
  transition: none;
}

.answer-outline {
  outline: var(--blue) solid 4px;
  outline-offset: -4px;
}

.answer-wrong, .answer-selected {
  background: var(--blue) !important;
  color: white !important;
  transition: none;
}

.groups-container {
  margin: 10px 0;
  overflow-y: hidden;
  overflow-x: auto;
  width: 100%;
  display: flex;

  button {
    color: white;
    border: none;
    margin-left: 4px;
    font-size: 28px;
    padding: 0 16px;
    font-weight: bold;
  }
}

.question-number {
  font-size: 24px;
  color: #007bff;
  margin-top: auto;
  margin-bottom: 0;
  display: flex;
}

.group-name {
  margin: 4px 0;
  color: #007bff;
  font-size: 22px;
}

.player-details-container {
  input[type="text"] {
    background: white !important;
    margin-bottom: 10px;
  }
}

.notice {

  position: relative;
  background-color: #49B864;
  padding: 10px;
  margin-bottom: 10px;

  h2 {
    font-size: 3vh;
  }

  p {
    margin-bottom: 0;
  }

  > span {
    position: absolute;
    right: 10px;
    top: 10px;
  }
}

.screen-feedback {
  padding-top: 32px;
  padding-bottom: 32px;

  iframe {
    direction: rtl !important;
    //height: 100vh;
  }

}

//themes
main.rock {

  .btn-1 {
    background: #7F3147;
    border-color: #7F3147;
    color: #ffffff;
  }

  .btn-2 {
    background: #D15F47;
    border-color: #D15F47;

    color: #ffffff;
  }

  .btn-3 {
    background: #F3C472;
    border-color: #F3C472;

    color: #000000;
  }

  .btn-4 {
    background: #FCF9D0;
    border-color: #FCF9D0;

    color: #000000;
  }

  .timer-left {
    background: #ffffff;
  }

  .question-number, .group-name {
    color: #ffffff;
  }

}
</style>
