<template>
  <div class="sidebar flex-column flex-apart" :class="{active: isActive}">
    <div>
      <div class="sidebar-row">
        <a class="text-white" @click="toggleActive" href="#">
          <font-awesome-icon icon="times"/>
        </a></div>
      <div class="sidebar-row flex-apart">
        <h3>{{ $parent.quiz ? $parent.quiz.name : '' }}</h3>
        <a class="ml-2 text-white text-center" :href="$parent.quiz.settings.helpUrl"
           v-if="$parent.quiz && $parent.quiz.settings.helpUrl"
           target="_blank" rel="noreferrer noopener">
          <font-awesome-icon icon="info"/>
        </a>
      </div>
      <div v-if="showWhatsapp" class="sidebar-row  flex-apart">
        <h3> Share on WhatsApp </h3>
        <a :href="whatsappUrl"><img alt="Whatsapp" class="ml-2" width="28" height="28"
                                    :src="defAssets.imgWhatsapp"/></a>
      </div>
      <div class="sidebar-row">
        <a class="d-block text-white" href="#" @click="resetUser">User reset</a>
      </div>
      <div class="sidebar-row">
        <a class="d-block text-white" href="#" @click="minimize">Minimize window</a>
      </div>
    </div>
    <div>
      <div class="sidebar-row">
        <a class="d-block text-center" href="https://www.quizygame.com" target="_blank"><span class="text-white">Powered By </span>QuizyGame.com</a>
        <p class="text-center"><small>The app uses cookies for it's core functionality. We do not share your
          details with 3rd parties.</small></p>
      </div>
    </div>
  </div>
</template>

<script>

import imgWhatsapp from '@/assets/img/whatsapp.png';

export default {
  props: ['isActive', 'code', 'showWhatsapp'],
  name: "SideBar",
  data() {
    return {
      link: '',
      defAssets: {}
    }
  },
  mounted() {
    this.defAssets = {imgWhatsapp};
    this.link = window.location.href;
  },
  methods: {
    toggleActive() {
      this.$parent.sidebar = false;
    },
    resetUser() {
      this.$parent.removeCookie("clientName");
      this.$parent.removeCookie("clientGroup");
      this.$parent.removeCookie("clientEmail");
      this.$parent.removeCookie("clientId");
      this.$parent.removeCookie("hashcode");
      location.reload();
    },
    minimize() {
      window.open(this.link, 'Quizy', 'height=648,width=360')
      window.close();
    }
  },
  computed: {
    whatsappUrl() {
      let url = encodeURI("https://quizy.show/" + this.code);
      return "whatsapp://send?text=מזמינים אותך להצטרך לשעשעון " + (this.$parent.quiz ? this.$parent.quiz.name : '') + " " + url;
    },
  }
}
</script>

<style scoped lang="scss">
.sidebar {
  width: 75vw;
  max-width: 400px;
  height: 100vh;
  background: rgba(0, 0, 0, 0.98);
  z-index: 10;
  right: 0;
  position: fixed;
  top: 0;

  > div {
    width: 100%
  }

  transform: translateX(100%);
  transition: all .5s ease;

  &.active {
    transform: translateX(0);
  }

  .sidebar-row {
    padding: 10px 20px;
    color: whitesmoke;

    &:not(:first-child):nth-child(even) {
      background: #202326;
    }

    h3, a {
      font-family: 'Assistant', sans-serif;
      font-size: 19px;
      text-align: left;
      min-width: 28px;
      text-decoration: none;
    }

    p {
      line-height: 16px;
    }

    small {
      line-height: 1;
      font-size: 75%;
      font-weight: 300;
    }
  }
}
</style>
